<template>
  <div class="container-fluid">
    <div class="row">
      <div class="pl-5 col">
        <div v-if="this.isPassTokenTrue">
          <h1 class="mb-4 h3">Регистрация. Установка пароля.</h1>
          <form @submit.prevent="onSubmit" v-if="this.isPassTokenTrue && !this.isPassCreated" class="pass-form">
            <b-form-group
              id="pass-group-1"
              label="Пароль"
              label-for="pass-1"
              description="Длина пароля не менее 8 символов, должен содержать как минимум 1 заглавную букву, 1 цифру и 1 спецсимвол (! @ # $ % ^ & *)."
            >
              <b-form-input id="pass-1" v-model="passInput" type="password" required @keyup="validPassword" placeholder="Введите пароль"></b-form-input>
              <span class="text-danger">{{ errorMsg }}</span>
              <span class="text-success">{{ successMsg }}</span>
            </b-form-group>

            <b-form-group id="pass-group-2" label="Подтвердите пароль" label-for="pass-2">
              <b-form-input id="pass-2" v-model="passConfirm" type="password" @keyup="checkConfirmation" placeholder="Введите пароль еще раз"></b-form-input>
              <span class="text-danger">{{ errorConfirmMsg }}</span>
              <span class="text-success">{{ successConfirmMsg }}</span>
            </b-form-group>

            <b-button type="submit" :disabled="!isPasswordCorrect()" variant="primary">Установить пароль</b-button>
          </form>
        </div>

        <div v-if="this.isPassTokenTrue == null">loading</div>
      </div>
    </div>

    <div class="row">
      <div class="pl-5 col">
        <div v-if="this.isPassTokenTrue == false">
          <p>Данная ссылка более не доступна.</p>
          <p>Для входа перейдите на главную страницу <a class="underline link" href="/">{{baseURL}}</a></p>
          <p>Для восстановления пароля воспользуйтесь кнопкой <span class="green">Забыли пароль?</span> на главной странице.</p>
        </div>
        <h4 class="h4" v-if="this.isPassCreated">Ваш пароль создан успешно. <a href="/">Перейти на страницу авторизации </a></h4>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapState } from "vuex";

export default {
  name: "CreatePassword",
  data() {
    return {
      passInput: "",
      passConfirm: "",
      errorMsg: "",
      successMsg: "",
      errorConfirmMsg: "",
      successConfirmMsg: "",
      isPasswordValid: false,
      isPasswordConfirmed: false,
      user: {}
    };
  },
  methods: {
    ...mapActions(["checkPasswordToken", "createPassword"]),
    onSubmit() {
      const pass = {
        userId: this.user.userId,
        password: this.passInput,
        confirmPassword: this.passConfirm,
        token: this.user.token
      };
      this.createPassword(pass);
      if (this.isPassCreated) {
        setTimeout(() => this.$router.push("/login"), 3000);
      }
    },
    validPassword() {
      console.log(this.passInput);
      if (this.passInput.length >= 8 && this.passInput.match(/(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])/)) {
        this.isPasswordValid = true;
        this.errorMsg = "";
        this.successMsg = "Хороший пароль";
      } else {
        this.errorMsg = "Ваш пароль не соответствует условию";
        this.successMsg = "";
        this.isPasswordValid = false;
      }
    },
    checkConfirmation() {
      if (this.passInput == this.passConfirm) {
        this.successConfirmMsg = "Пароль совпадает";
        this.errorConfirmMsg = "";
        this.isPasswordConfirmed = true;
      } else {
        this.successConfirmMsg = "";
        this.errorConfirmMsg = "Пароль не совпадает";
        this.isPasswordConfirmed = false;
      }
    },
    isPasswordCorrect() {
      if (this.isPasswordValid && this.isPasswordConfirmed) {
        return true;
      } else return false;
    }
  },
  computed: {
    ...mapState({
      isPassTokenTrue: state => state.auth.checkPassToken,
      isPassCreated: state => state.auth.passCreated
    }),
    baseURL() {
      return window.location.origin;
    }
  },
  created() {
    let params = window.location.href.split("?")[1].split("&");
    console.log(params);
    params.forEach(item => {
      let query = item.split("=");
      this.user[query[0]] = query[1];
    });
    this.user.token = decodeURIComponent(this.user.token);
    this.checkPasswordToken(this.user);
  }
};
</script>

<style lang="scss" scoped>
p {
  font-size: 1.2rem;
}
.underline {
  text-decoration: underline;
}
.green {
  color: $green;
}
.pass-form {
  max-width: 330px;
}
</style>
